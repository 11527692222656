import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import { getUnique } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  methods: {
    canSendNotificationsToEmployee(employee) {
      return (
        employee &&
        employee.user &&
        (employee.user.email ||
          (employee.user.default_phone && employee.user.default_phone.e164) ||
          employee.user.accepts_push)
      );
    },
    canSendNotificationsToUser(user) {
      return (
        user.email ||
        (user.default_phone && user.default_phone.e164) ||
        user.accepts_push
      );
    },
    sendNotificationsToUsers(users, customerType, title) {
      this.openDialog({
        type: dialogTypes.SEND_CUSTOMER_NOTIFICATION,
        title: title,
        size: dialogSize.LARGE,
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$send",
        ctaButtonTitle: this.$trans("send"),
        data: {
          selectedUsers: getUnique(users, "id"),
          customerType: customerType,
        },
      });
    },
  },
};
