<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersCreatedDateRange"
          only-past-dates
          :label="$trans('created_date_range')"
          clearable
          dense
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import CalendarRangeInput from "@/lib/calendesk-js-library/components/CalendarRangeInput.vue";

export default {
  name: "CustomerNotificationsFilter",
  components: { CalendarRangeInput },
  mixins: [sharedListFilters],
  data() {
    return {
      cacheId: "newsletter-list-filters",
      selectedFiltersSearch: null,
      selectedFiltersCreatedDateRange: null,
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersSearch: this.selectedFiltersSearch,
        selectedFiltersCreatedDateRange: this.selectedFiltersCreatedDateRange,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      if (
        this.selectedFiltersCreatedDateRange &&
        this.selectedFiltersCreatedDateRange.length > 0
      ) {
        data.created_from = this.selectedFiltersCreatedDateRange[0];

        if (this.selectedFiltersCreatedDateRange.length === 2) {
          data.created_to = this.selectedFiltersCreatedDateRange[1];
        } else {
          data.created_to = data.created_from;
        }
      }

      return data;
    },
  },
};
</script>
