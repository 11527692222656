import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import { mapActions } from "vuex";
import planActions from "@/calendesk/mixins/planActions";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import {
  createEventDataFromBookingRawEvent,
  createEventDataFromGroupBookingRawEvent,
  createEventDataFromUnavailableBookingSlotDataRawEvent,
} from "@/calendesk/tools/calendarHelpers";
import { bookingStatuses } from "@/calendesk/prototypes/bookingStatuses";
import eventParser from "@/calendesk/mixins/eventParser";
import sharedListActions from "@/calendesk/mixins/sharedListActions";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  mixins: [planActions, eventParser, sharedListActions, roleActions],
  data() {
    return {
      eventTypes: this.$helpers.eventTypes,
    };
  },
  methods: {
    ...mapActions({
      deleteBooking: "booking/deleteBooking",
      deleteUnavailableBookingSpot: "booking/deleteUnavailableBookingSpot",
      setCreateBookingTransactionDialog:
        "booking/setCreateBookingTransactionDialog",
      updateMultipleBookings: "booking/updateMultiple",
      deleteMultipleBookings: "booking/deleteMultiple",
      deleteMultipleUnavailableBookingSpot:
        "booking/deleteMultipleUnavailableBookingSpot",
    }),
    fireAction(action, parsedEvent) {
      switch (action) {
        case "preview":
          this.showEvent(parsedEvent);
          break;
        case "edit":
          this.editEvent(parsedEvent);
          break;
        case "duplicate":
          this.duplicateEvent(parsedEvent);
          break;
        case "cancel":
          this.cancelEvent(parsedEvent);
          break;
        case "delete":
          if (this.isGroupBooking(parsedEvent)) {
            this.openConfirmDialog({
              type: confirmDialogTypes.REMOVE_EVENTS,
              data: {
                events: [parsedEvent],
                skipNotifications: false,
              },
            });
          } else {
            this.removeEvent(parsedEvent);
          }
          break;
        case "pay":
          if (parsedEvent.type === this.eventTypes.booking) {
            this.payForEvent(parsedEvent);
          }
          break;
        case "send_notification_to_customers":
          this.sendNotificationForEvents(
            [parsedEvent],
            this.$helpers.customerNotificationCustomerType.user
          );
          break;
        case "send_notification_to_employees":
          this.sendNotificationForEvents(
            [parsedEvent],
            this.$helpers.customerNotificationCustomerType.employee
          );
          break;
        case "payments":
          if (parsedEvent.type === this.eventTypes.booking) {
            this.openPaymentsDialog(parsedEvent);
          }
          break;
      }
    },
    canEventBeDuplicated(item) {
      const type = item.event_type ? item.event_type : item.type;
      const multiSlotGroupId = item.event_data
        ? item.event_data.multi_slot_group_id
        : item.multiSlotGroupId;

      return (
        (type === this.eventTypes.booking && !multiSlotGroupId) ||
        type === this.eventTypes.groupBooking ||
        type === this.eventTypes.unavailableBookingSlot
      );
    },
    canItemHandlePayments(item) {
      const type = item.event_type ? item.event_type : item.type;

      return type === this.eventTypes.booking;
    },
    isUnavailableBookingSlot(item) {
      const type = item.event_type ? item.event_type : item.type;

      return type === this.eventTypes.unavailableBookingSlot;
    },
    isBookingAndHasStatus(item, status) {
      const type = item.event_type ? item.event_type : item.type;
      const data = item.event_data ? item.event_data : item;

      return type === this.eventTypes.booking && data.status === status;
    },
    isGroupBooking(item) {
      const type = item.event_type ? item.event_type : item.type;

      return type === this.eventTypes.groupBooking;
    },
    isBookingAndHasNotHaveStatus(item, status) {
      const type = item.event_type ? item.event_type : item.type;
      const data = item.event_data ? item.event_data : item;

      return type === this.eventTypes.booking && data.status !== status;
    },
    canCancelItem(item) {
      return (
        this.isBookingAndHasNotHaveStatus(item, bookingStatuses.cancelled) ||
        this.isGroupBooking(item)
      );
    },
    canDeleteItem(item) {
      const type = item.event_type ? item.event_type : item.type;

      return (
        ((type === this.eventTypes.booking ||
          type === this.eventTypes.groupBooking) &&
          this.loggedUserCanDeleteBookings) ||
        type === this.eventTypes.unavailableBookingSlot
      );
    },
    canItemSendNotificationsToCustomers(item) {
      const type = item.event_type ? item.event_type : item.type;
      const user = item.event_data ? item.event_data.user : item.user;

      return (
        (type === this.eventTypes.booking &&
          user &&
          this.canSendNotificationsToUser(user)) ||
        type === this.eventTypes.groupBooking
      );
    },
    canItemSendNotificationsToEmployees(item) {
      const employee = item.event_data
        ? item.event_data.employee
        : item.employee;

      return this.canSendNotificationsToEmployee(employee);
    },
    showEvent(parsedEvent) {
      pushEvent("showEvent");
      this.$root.$emit("openEventPreviewModal", parsedEvent);
    },
    editEvent(parsedEvent) {
      pushEvent("editEvent");
      this.$root.$emit("closeEventPreviewModal");
      this.$root.$emit("openEventModal", parsedEvent);
    },
    duplicateEvent(parsedEvent) {
      pushEvent("duplicateEvent");
      parsedEvent.duplicate = true;
      this.$root.$emit("closeEventPreviewModal");
      this.$root.$emit("openEventModal", parsedEvent);
    },
    cancelEvent(parsedEvent) {
      pushEvent("cancelEvent");
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmationText: this.$trans("change_status"),
        confirmAction: () => {
          this.updateBookingStatusForMultiple(
            Array.isArray(parsedEvent) ? parsedEvent : [parsedEvent],
            "cancelled"
          );
        },
      });
    },
    async removeEvent({ id, groupId, type }) {
      pushEvent("removeEvent");

      const emitCloseModal = (reloadCalendar) =>
        this.$root.$emit("closeEventPreviewModal", reloadCalendar);

      if (groupId) {
        this.openConfirmDialog({
          type: confirmDialogTypes.REMOVE_RECURRING_EVENT,
          data: {
            id,
            groupId,
            skipNotifications: false,
            isBlockingSpot: type === this.eventTypes.unavailableBookingSlot,
          },
          close: function (reloadCalendar) {
            emitCloseModal(reloadCalendar);
          },
        });
      } else {
        if (type === this.eventTypes.unavailableBookingSlot) {
          this.openConfirmDialog({
            type: sharedConfirmDialogTypes.COMMON,
            confirmAction: async () => {
              this.setCommonDialogLoader(true);

              try {
                await this.deleteUnavailableBookingSpot(id);
                emitCloseModal(true);
              } finally {
                this.setCommonDialogLoader(false);
                this.closeConfirmDialog();
              }
            },
          });
        } else {
          this.openConfirmDialog({
            type: confirmDialogTypes.REMOVE_EVENTS,
            data: {
              bookingIds: [id],
              skipNotifications: false,
            },
            close: function (reloadCalendar) {
              emitCloseModal(reloadCalendar);
            },
          });
        }
      }
    },
    getEventIds(events) {
      const { booking, groupBooking, unavailableBookingSlot } =
        this.$helpers.eventTypes;

      const bookingIds = events
        .filter(
          (obj) =>
            obj.type === booking ||
            obj.event_type === booking ||
            obj.event_type === booking
        )
        .map((obj) => obj.id);

      const unavailableBookingSlotIds = events
        .filter(
          (obj) =>
            obj.type === unavailableBookingSlot ||
            obj.event_type === unavailableBookingSlot
        )
        .map((obj) => obj.id);

      const groupBookingIds = events
        .filter(
          (obj) => obj.type === groupBooking || obj.event_type === groupBooking
        )
        .reduce(
          (ids, obj) => [...ids, ...obj.bookings.map((booking) => booking.id)],
          []
        );

      return { bookingIds, unavailableBookingSlotIds, groupBookingIds };
    },
    async updateBookingStatusForMultiple(events, status) {
      pushEvent("updateBookings");

      this.setCommonDialogLoader(true);
      const { bookingIds, groupBookingIds } = this.getEventIds(events);

      const payload = {
        booking_ids: [...bookingIds, ...groupBookingIds],
        fields: {
          status,
        },
      };

      try {
        await this.updateMultipleBookings(payload);
      } catch (error) {
        errorNotification(null, error, false);
      } finally {
        this.closeConfirmDialog();
        this.setCommonDialogLoader(false);
      }
    },
    openPaymentsDialog(parsedEvent) {
      this.openDialog({
        type: dialogTypes.USER_PAYMENT_TRANSACTIONS,
        size: this.getLargeDialogWidth,
        title: this.$trans("payment_history"),
        data: {
          user: parsedEvent.user,
          paymentMode: 1,
          bookingId: parsedEvent.id,
          booking: parsedEvent,
        },
      });
    },
    payForEvent(parsedEvent) {
      let price =
        parsedEvent.service && parsedEvent.service.selectedType
          ? parsedEvent.service.selectedType.price
          : parsedEvent.service
          ? parsedEvent.service.price
          : 0;

      this.setCreateBookingTransactionDialog({
        booking: parsedEvent,
        amount: price,
      });
    },
    sendNotificationForEvents(items, customerType) {
      if (this.canSendCustomerNotifications) {
        if (items && items.length > 0) {
          const users = this.getNotificationUsers(items, customerType);
          const title =
            customerType ===
            this.$helpers.customerNotificationCustomerType.employee
              ? this.$trans("send_employees_notification_title")
              : this.$trans("send_customers_notification_title");

          this.sendNotificationsToUsers(users, customerType, title);
        }
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },

    getNotificationUsers(items, customerType) {
      const users = [];

      items.forEach((item) => {
        const { event_type, event_data, type, user, employee, bookings } = item;
        const actualType = event_type || type;
        const actualUser = event_data?.user || user;
        const actualEmployee = event_data?.employee || employee;
        const actualBookings = event_data?.bookings || bookings;

        switch (actualType) {
          case this.eventTypes.unavailableBookingSlot:
            if (
              customerType ===
                this.$helpers.customerNotificationCustomerType.employee &&
              actualEmployee
            ) {
              users.push(actualEmployee);
            }
            break;
          case this.eventTypes.booking:
            if (actualUser) {
              users.push(
                customerType ===
                  this.$helpers.customerNotificationCustomerType.user
                  ? actualUser
                  : actualEmployee
              );
            }
            break;
          case this.eventTypes.groupBooking:
            if (
              customerType ===
                this.$helpers.customerNotificationCustomerType.user &&
              actualBookings
            ) {
              users.push(
                ...actualBookings.map(
                  (booking) =>
                    booking[
                      customerType ===
                      this.$helpers.customerNotificationCustomerType.user
                        ? "user"
                        : "employee"
                    ]
                )
              );
            }
            break;
          default:
            break;
        }
      });

      return users;
    },
    getParsedEvent(item) {
      switch (this.getEventTypeForItem(item)) {
        case this.eventTypes.booking:
          return createEventDataFromBookingRawEvent(item);
        case this.eventTypes.groupBooking:
          return createEventDataFromGroupBookingRawEvent(item);
        case this.eventTypes.unavailableBookingSlot:
          return createEventDataFromUnavailableBookingSlotDataRawEvent(item);
      }

      return null;
    },
    getEventTypeForItem(item) {
      if (item.event_type === this.eventTypes.booking) {
        return this.eventTypes.booking;
      } else if (item.event_type === this.eventTypes.groupBooking) {
        return this.eventTypes.groupBooking;
      } else if (item.event_type === this.eventTypes.unavailableBookingSlot) {
        return this.eventTypes.unavailableBookingSlot;
      } else if (item.multi_slot_group_id) {
        return this.eventTypes.booking;
      }

      return null;
    },
  },
};
